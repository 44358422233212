import React from "react";
import styled from "styled-components";
import Scroll from 'react-scroll';
import titleLogo from '../../media/image/IntroCompany/dingbat@3x.webp'
import twentyImage from '../../media/image/IntroCompany/lead@3x.webp'
import logo1 from '../../media/image/IntroCompany/icon-1.webp'
import logo2 from '../../media/image/IntroCompany/icon-2.webp'
import logo3 from '../../media/image/IntroCompany/icon-3.webp'
import logo4 from '../../media/image/IntroCompany/icon-4.webp'
import check from '../../media/image/IntroCompany/check.webp'
import backImage from '../../media/image/IntroCompany/sub-bg@3x.webp'
import {useIsDesktop} from "../../hooks/useIsDesktop";
import {Parallax} from 'react-scroll-parallax';
import { AnimationOnScroll } from 'react-animation-on-scroll';


export const IntroCompany = () => {
  let Element = Scroll.Element;
  const isDesktop = useIsDesktop();


  return(
    <Element name={'IntroCompany-Scroll'}>
    <IntroComContainer>

      <TitleWrapper>
        <TitleImage src={titleLogo} alt={'title-logo'} isDesktop={isDesktop} loading="lazy"/>
        <Title isDesktop={isDesktop}>
          회사소개
        </Title>
      </TitleWrapper>

      <TwentyWrapper isDesktop={isDesktop}>
        <TwentyImage src={twentyImage} alt={'twenty-image'} isDesktop={isDesktop} loading="lazy"/>
        <TwentyText isDesktop={isDesktop}>
          {`20년이라는 짧지 않은 시간동안 고객님과 함께 해온 국가대표 콜 서비스는
          그 시간의 무게만큼우리 업에 대한 책무를 느낍니다.
          우리의 어깨가 무거울수록 보다 안전하고 평안한 여정이 된다고 믿기에
          오늘도 우리는강한 책임감으로 고객님을 모십니다`}
        </TwentyText>
      </TwentyWrapper>

      <SideWrapper isDesktop={isDesktop}>
        <LeftTextWrapper isDesktop={isDesktop}>
          <AnimationOnScroll animateIn="animate__backInLeft" animatePreScroll={false} animateOnce={true}>
            <LeftSideTextWrapper isDesktop={isDesktop}>
              <SideTitle isDesktop={isDesktop}>
                Standard
              </SideTitle>
              <SideSubTitle isDesktop={isDesktop}>
                업계의 새로운 기준을 만들어 갑니다
              </SideSubTitle>
              <SideBodyText isDesktop={isDesktop}>
                단순한 운전기사의 연결이 아닌 책임감을 갖고 사업을 이어갑니다
              </SideBodyText>
            </LeftSideTextWrapper>
          </AnimationOnScroll>


          <AnimationOnScroll animateIn="animate__backInLeft" animatePreScroll={false} animateOnce={true}>
          <LeftSideTextWrapper isDesktop={isDesktop} >
            <SideTitle isDesktop={isDesktop}>
              Responsibility
            </SideTitle>
            <SideSubTitle isDesktop={isDesktop}>
              할수 있는 모든것을 책임지겠습니다
            </SideSubTitle>
            <SideBodyText isDesktop={isDesktop}>
              {`사고가 날 수는 있지만 사고초리에 대한 불만은 있을 수 없는 일입니다
              그리고 그것이 책임감이라고 믿고 있습니다`}
            </SideBodyText>
          </LeftSideTextWrapper>
          </AnimationOnScroll>
        </LeftTextWrapper>

        <IconWrapper>
          <AnimationOnScroll animateIn="animate__zoomIn" animatePreScroll={false} animateOnce={true}>
            <IconImage src={logo1} isDesktop={isDesktop} loading="lazy"/>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__zoomIn" animatePreScroll={false} animateOnce={true}>
            <IconImage src={logo2} isDesktop={isDesktop} loading="lazy"/>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__zoomIn" animatePreScroll={false} animateOnce={true}>
            <IconImage src={logo3} isDesktop={isDesktop} loading="lazy"/>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__zoomIn" animatePreScroll={false} animateOnce={true}>
            <IconImage src={logo4} isDesktop={isDesktop} loading="lazy"/>
          </AnimationOnScroll>
        </IconWrapper>

        <RightTextWrapper isDesktop={isDesktop}>
          <AnimationOnScroll animateIn="animate__backInRight" animatePreScroll={false} animateOnce={true}>
            <RightSideTextWrapper isDesktop={isDesktop}  >
              <SideTitle isDesktop={isDesktop}>
                Confidence
              </SideTitle>
              <SideSubTitle isDesktop={isDesktop}>
                어떤 상황에도 믿을 수 있습니다
              </SideSubTitle>
              <SideBodyText isDesktop={isDesktop}>
                {`첫 배차에서 마지막 주차까지 책임감을 갖고 고객을 대합니다
고객과 기사간의 신뢰의 관계를 만들어가고 있습니다`}
              </SideBodyText>
            </RightSideTextWrapper>
            </AnimationOnScroll>

          <AnimationOnScroll animateIn="animate__backInRight" animatePreScroll={false} animateOnce={true}>
          <RightSideTextWrapper isDesktop={isDesktop}  >
            <SideTitle isDesktop={isDesktop}>
              Security
            </SideTitle>
            <SideSubTitle isDesktop={isDesktop}>
              고객의 정보까지도 보호합니다
            </SideSubTitle>
            <SideBodyText isDesktop={isDesktop}>
              {`법인 고객의 개인정보 및 영업비밀에  대한 중요성을 인지하고 
보안유지를 서약합니다`}
            </SideBodyText>
          </RightSideTextWrapper>
          </AnimationOnScroll>
        </RightTextWrapper>
      </SideWrapper>

    </IntroComContainer>


        <BottomImageWrapper isDesktop={isDesktop}>
          <Parallax speed={8}>
            <AnimationOnScroll animateIn="animate__fadeInDownBig" animatePreScroll={false} animateOnce={true}>
            <BottomCheckImage src={check} alt={'check-image'} isDesktop={isDesktop} loading="lazy"/>
            </AnimationOnScroll>
          <BottomBackImage src={backImage} alt={'bottom-back-image'} isDesktop={isDesktop} loading="lazy"/>
          </Parallax>
        </BottomImageWrapper>
    </Element>
  )
}



// ---------------------------------Style Side----------------------------------

const IntroComContainer = styled.div`
  background-color: white;
  padding-top: 58px;
  padding-left: 66px;
  padding-right: 66px;
  display: flex;
  flex-direction: column;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

const TitleImage = styled.img`
  width: ${props => props.isDesktop ? '1.23vw' : '22px'};
  height: ${props => props.isDesktop ? '1.23vw' : '22px'};
  margin-right: 10px;
`

const Title = styled.div`
  font-size: ${props => props.isDesktop ? '2.29vw' : '41px'};
  letter-spacing: -2.05px;
  color: #414042;
  font-family: NotoSansCJKkr_Bold sans-serif;
  font-weight: bold;
  padding-top: ${props => props.isDesktop ? '0.50vw' : '9px'};
`

const TwentyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${props => props.isDesktop ? '4.53vw' : '81px'};
`

const TwentyImage = styled.img`
  width: ${props => props.isDesktop ? '14.25vw' : '255px'};
`

const TwentyText = styled.div`
  white-space: pre-wrap;
  font-size: ${props => props.isDesktop ? '1.37vw' : '24.5px'};
  line-height: 1.2;
  letter-spacing: -1.23px;
  text-align: center;
  font-family: NotoSansCJKkr_Medium,serif ;
  margin-top: 26px;
`


const SideWrapper = styled.div`
  margin-top: ${props => props.isDesktop ? '1.90vw' : '34px'};
  display: flex;
  justify-content: center;
`


const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
`


const LeftTextWrapper = styled.div`
  margin-top: ${props => props.isDesktop ? '0.56vw' : '10px'};
`

const RightTextWrapper = styled.div`
  margin-top: ${props => props.isDesktop ? '11.28vw' : '202px'};
`

const IconImage = styled.img`
  width: ${props => props.isDesktop ? '6.59vw' : '118px'};
  height: ${props => props.isDesktop ? '6.59vw' : '118px'};
  margin: ${props => props.isDesktop ? '2.21vw 2.51vw' : '39.5px 45px'};
`

const LeftSideTextWrapper = styled.div`
  text-align: right;
  margin-bottom: ${props => props.isDesktop ? '12.85vw' : '230px'};
`

const RightSideTextWrapper = styled.div`
  text-align: left;
  margin-bottom: ${props => props.isDesktop ? '12.85vw' : '230px'};
`

const SideTitle = styled.div`
  font-size: ${props => props.isDesktop ? '3.41vw' : '61px'};
  font-family: NotoSansCJKkr_Regular,serif ;
  line-height: 1.43;
  letter-spacing: -1.22px;
  color: #e6e7e8;
  font-style: italic;
`

const SideSubTitle = styled.div`
  font-size: ${props => props.isDesktop ? '1.65vw' : '29.5px'};
  font-weight: 500;
  letter-spacing: -0.89px;
  font-family: NotoSansCJKkr_Medium,serif ;
`

const SideBodyText = styled.div`
  white-space: pre-wrap;
  font-size: ${props => props.isDesktop ? '1.31vw' : '23.5px'};
  font-family: NotoSansCJKkr_DemiLight,serif;
  line-height: 1.26;
  letter-spacing: -0.71px;
  color: #6d6e71;
`

const BottomImageWrapper = styled.div`
  position: relative;
  margin-top: ${props => props.isDesktop ? '-2.23vw' : '-40px'};
  background-color: white;
`

const BottomCheckImage = styled.img`
  position: absolute;
  top: ${props => props.isDesktop ? '-3.91vw' : '-70px'};
  right: 19.5%;
  width: ${props => props.isDesktop ? '7.04vw' : '126px'};
`

const BottomBackImage = styled.img`
  width: 100%;
`