import React from "react";
import styled from "styled-components";

export const ShareFooterMobile = () => {
  return(
    <ShareFooterContainer>
      <LeftTextWrapper>
        <CompanyName>㈜국가대표</CompanyName>

        <BodyText>
          {`서울틀별시 노원구 동일로207길 23, 4층 414호
대표 : 김성환  사업자번호 : 599-86-02759
국가대표 : 3000-3000
고객문의 : 080-132-3000`}
        </BodyText>

        <CopyRightsText>
          copyrights © 2022 국가대표. all rights reserved.
        </CopyRightsText>
      </LeftTextWrapper>

    </ShareFooterContainer>
  )
}

const ShareFooterContainer = styled.div`
  overflow: hidden;
  background-color: #1e3241;
  padding: 25px 0;
  display: flex;
  justify-content: center;
`

const LeftTextWrapper = styled.div`
  text-align: center;
`

const CompanyName = styled.div`
  color: #d1d3d4;
  font-size: 4.76vw;
  font-family: NotoSansCJKkr_Bold,serif;
  font-weight: bold;
  margin-bottom: 5.73vw;
`

const BodyText = styled.div`
  color: #d1d3d4;
  white-space: pre-wrap;
  font-size: 3.07vw;
  font-family: NotoSansCJKkr_DemiLight,serif;
  font-weight: 300;
`

const CopyRightsText = styled.div`
  color: #d1d3d4;
  font-size: 3.07vw;
  font-family: NotoSansCJKkr_DemiLight,serif;
  font-weight: 300;
  margin-top: 5.89vw;
`
