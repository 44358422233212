import React from "react";
import styled from "styled-components";
import Scroll from 'react-scroll';
import {HolderPaperMobile} from "../Desktop/blocks/ContractInfo/mobile/HolderPaperMobile";


export const ContractInfoMobile = () => {
  let Element = Scroll.Element;

  return(
    <Element name={'ContractInfo-Scroll'}>
      <ContractInfoContainer>
        <TitleWrapper>
          <Title>
            계약안내
          </Title>
        </TitleWrapper>

        <HolderPaperMobile />
      </ContractInfoContainer>
    </Element>
  )
}


const ContractInfoContainer = styled.div`
  background-color: white;
  padding-top: 20vw;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled.div`
  font-size: 9.77vw;
  letter-spacing: -2.05px;
  color: #414042;
  font-family: NotoSansCJKkr_Bold sans-serif;
  font-weight: bold;
  margin-bottom: 6vw;
`