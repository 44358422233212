import React from "react";
import styled from "styled-components";
import titleLogo from "../../media/image/IntroCompany/dingbat@3x.webp";
import {HolderPaper} from "./blocks/ContractInfo/HolderPaper";
import Scroll from 'react-scroll';
import {useIsDesktop} from "../../hooks/useIsDesktop";

export const ContractInfo = () => {
  let Element = Scroll.Element;
  const isDesktop = useIsDesktop();

  return(
    <Element name={'ContractInfo-Scroll'}>
      <ContractInfoContainer isDesktop={isDesktop}>
        <TitleWrapper isDesktop={isDesktop}>
          <TitleImage src={titleLogo} alt={'title-logo'} isDesktop={isDesktop} loading="lazy"/>
          <Title isDesktop={isDesktop}>
            계약안내
          </Title>
        </TitleWrapper>

        <HolderPaper />
      </ContractInfoContainer>
    </Element>
  )
}


const ContractInfoContainer = styled.div`
  background-color: white;
  padding-top: ${props => props.isDesktop ? '6.87vw' : '123px'};
  padding-bottom: ${props => props.isDesktop ? '10.39vw' : '186px'};
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${props => props.isDesktop ? '3.69vw' : '66px'};
`

const TitleImage = styled.img`
  width: ${props => props.isDesktop ? '1.23vw' : '22px'};
  margin-right: ${props => props.isDesktop ? '0.56vw' : '10px'};
`

const Title = styled.div`
  font-size: ${props => props.isDesktop ? '2.29vw' : '41px'};
  letter-spacing: -2.05px;
  color: #414042;
  font-family: NotoSansCJKkr_Bold sans-serif;
  font-weight: bold;
  padding-top: ${props => props.isDesktop ? '0.50vw' : '9px'};
`