import React from "react";
import styled from "styled-components";
import number04Image from "../../../../../media/image/ContractInfo/PublishTex/04.webp";
import main04Image from "../../../../../media/image/ContractInfo/PublishTex/shutterstock-770002474.webp";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export const PublishTexMobile = () => {
  return(
    <AnimationOnScroll animateIn="animate__fadeIn" animatePreScroll={false} animateOnce={true}>
    <PublishTexContainer>
      <HeaderWrapper>
        <NumberImage src={number04Image} alt={"number-01-image"} loading="lazy"/>
        <Title>세금계산서 발행</Title>
      </HeaderWrapper>

      <MainImage src={main04Image} loading="lazy"/>

      <SubTitleWrapper>
        <SubTitle>➊ 월단위 이용내역서 제공</SubTitle>
        <SubTitle>➋ 내역 검토 후 전자세금계산서 발행</SubTitle>
      </SubTitleWrapper>

    </PublishTexContainer>
    </AnimationOnScroll>
  )
}

const PublishTexContainer = styled.div`
  margin-top: 10vw;
  margin-left:  1.94vw;
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.95vw;
`

const NumberImage = styled.img`
  width: 15.82vw;
  margin-right: 5.65vw;
`

const Title = styled.div`
  font-size: 4.64vw;
  letter-spacing: -1.43px;
  font-weight: bold;
  font-family: NotoSansCJKkr_Bold, serif;
  color: #414042;
`

const MainImage = styled.img`
  width: 85.55vw;
`

const SubTitleWrapper = styled.div`
  margin-top: 1.01vw;
`

const SubTitle = styled.div`
  font-size:  3.95vw;
  font-family: NotoSansCJKkr_Regular,serif;
  letter-spacing: -1.23px;
  color: #414042;
`