import React from "react";
import styled from "styled-components";
import logo_image from '../../media/image/ShareHeader/logo@3x.webp'
import Scroll from "react-scroll"
import {useIsDesktop} from "../../hooks/useIsDesktop";

export const ShareHeader = () => {
  let scroller = Scroll.scroller;
  const isDesktop = useIsDesktop();

  return (
    <HeaderContainer isDesktop={isDesktop}>

      <div style={{display:"flex", width: "70vw", justifyContent:'space-between'}}>
        <LogoImage src={logo_image} alt={'logo-image'} isDesktop={isDesktop} loading="lazy"/>

        <TextWrapper>
          <TextItem
            isDesktop={isDesktop}
            onClick={() => {
              scroller.scrollTo("TopMain-Scroll", {
                duration: 600,
                smooth: true,
                offset: 0,
              })
            }}>
            HOME
          </TextItem>
          <TextItem
            isDesktop={isDesktop}
            onClick={() => {
              scroller.scrollTo("IntroCompany-Scroll", {
                duration: 800,
                smooth: true,
                offset: -73,
              })
            }}>
            회사소개
          </TextItem>
          <TextItem
            isDesktop={isDesktop}
            onClick={() => {
              scroller.scrollTo("IntroJob-Scroll", {
                duration: 800,
                smooth: true,
                offset: 100,
              })
            }}>
            사업소개
          </TextItem>
          <TextItem
            isDesktop={isDesktop}
            onClick={() => {
              scroller.scrollTo("ContractInfo-Scroll", {
                duration: 800,
                smooth: true,
                offset: 0,
              })
            }}>
            계약안내
          </TextItem>
        </TextWrapper>
      </div>

    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  background-color: white;
  height: ${props => props.isDesktop ? '4.08vw' : '73px'};
  border-bottom: 1px solid #a7a9ac;
  display: flex;
  align-items: center;
  padding: 0 60px;
  position: sticky;
  top: 0;
  z-index: 999;
`

const LogoImage = styled.img`
  width: ${props => props.isDesktop ? '8.16vw' : '146px'};
  height: ${props => props.isDesktop ? '1.45vw' : '26px'};
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`

const TextItem = styled.div`
  font-family: NotoSansCJKkr_Medium, serif;
  font-size: ${props => props.isDesktop ? '1.34vw' : '24px'};
  font-weight: 500;
  color: #6d6e71;
  line-height: 0.83;
  letter-spacing: -1.68px;
  cursor: pointer;
  margin-right: 5vw;
`