import React from "react";
import styled from "styled-components";
import holderImage from '../../../../../media/mobileImage/ContractInfo/invalid-name.webp'
import {PhoneServiceMobile} from "./PhoneServiceMobile";
import {ContractMobile} from "./ContractMobile";
import {UsageServiceMobile} from "./UsageServiceMobile";
import {PublishTexMobile} from "./PublishTexMobile";
import {PaymentMobile} from "./PaymentMobile";
import bottomImage from "../../../../../media/mobileImage/ContractInfo/Payment/invalid-name.webp";

export const HolderPaperMobile = () => {
  return(
    <HolderPaperContainer>
      <Title>국가대표 콜 서비스 이용 방법</Title>
      <HolderImage src={holderImage} alt={'holder-image'} loading="lazy"/>

      <ItemWrapper>
        <PhoneServiceMobile />

        <ContractMobile />
      </ItemWrapper>

      <UsageServiceMobile />

      <PublishTexMobile />

      <PaymentMobile />

      <BottomImage src={bottomImage} loading="lazy"/>

    </HolderPaperContainer>
  )
}

const HolderPaperContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: #E6E7E8;
`

const HolderImage = styled.img`
  width: 100%;
`

const Title = styled.div`
  font-size: 5.93vw;
  position: absolute;
  letter-spacing: -3.68px;
  font-family: NotoSansCJKkr_Bold,serif;
  color: #414042;
  top: 16.5vw;
  left: 13vw;
`

const ItemWrapper = styled.div`
  position: absolute;
  top: 36vw;
  padding-left: 1.94vw;
  background-color: #E6E7E8;
`

const BottomImage = styled.img`

`