import React from "react";
import styled from "styled-components";
import number02Image from "../../../../media/image/ContractInfo/Contract/02.webp";
import main02Image from "../../../../media/image/ContractInfo/Contract/shutterstock-1972788395.webp";
import {useIsDesktop} from "../../../../hooks/useIsDesktop";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export const Contract = () => {
  const isDesktop = useIsDesktop();

  return(
    <AnimationOnScroll animateIn="animate__fadeIn" animatePreScroll={false} delay={1500} animateOnce={true} >
    <ContractContainer isDesktop={isDesktop} >
      <HeaderWrapper isDesktop={isDesktop}>
        <NumberImage src={number02Image} alt={"number-02-image"} isDesktop={isDesktop} loading="lazy"/>
        <Title isDesktop={isDesktop}>계약체결</Title>
      </HeaderWrapper>

      <MainImage src={main02Image} isDesktop={isDesktop} loading="lazy"/>

      <SubTitle isDesktop={isDesktop}>{`➊ 상담 신청 후 안내 자료 및 계약서 발송    ➋ 자료 및 계약서 검토 후 계약 체결    ➌ 이용자 등록 및 사전 설정`}</SubTitle>
    </ContractContainer>
    </AnimationOnScroll>
  )
}

const ContractContainer = styled.div`
  position: absolute;
  top: ${props => props.isDesktop ? '15.68vw' : '281px'};
  right: ${props => props.isDesktop ? '7.26vw' : '130px'};
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.isDesktop ? '0.95vw' : '17px'};
`

const NumberImage = styled.img`
  width: ${props => props.isDesktop ? '6.65vw' : '119px'};
  margin-right: ${props => props.isDesktop ? '2.68vw' : '48px'};
`

const Title = styled.div`
  font-size: ${props => props.isDesktop ? '1.59vw' : '28px'};
  letter-spacing: -1.43px;
  font-weight: bold;
  font-family: NotoSansCJKkr_Bold, serif;
  color: #414042;
`

const MainImage = styled.img`
  width: ${props => props.isDesktop ? '53.91vw' : '965px'};
  margin-bottom: 0.56vw;
`

const SubTitle = styled.div`
  white-space: pre-wrap;
  font-size:  ${props => props.isDesktop ? '1.33vw' : '24px'};
  font-family: NotoSansCJKkr_Regular,serif;
  letter-spacing: -1.23px;
  color: #414042;
`