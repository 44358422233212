import React from "react";
import styled from "styled-components";
import icon1 from '../../../../../media/mobileImage/IntroJob/ThreeSectionMobile/icon-01.webp'
import icon2 from '../../../../../media/mobileImage/IntroJob/ThreeSectionMobile/icon-02.webp'
import icon3 from '../../../../../media/mobileImage/IntroJob/ThreeSectionMobile/icon-03.webp'

export const ThreeSectionMobile = () => {

  return(
    <ThreeSectionContaianer>
      <ThreeHeader>
        <ThreeTitleSection>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <SectionTitleBar color={'#b4bac1'} />
            <ShapIcon>{`  # `}</ShapIcon>
            <ThreeTitle>일일기사대행</ThreeTitle>
          </div>
          <ThreeSubTitle>{`합리적인 서비스 경험,\n국가대표가 빠르고 안전하게 모십니다`}</ThreeSubTitle>
        </ThreeTitleSection>
      </ThreeHeader>

      <IconItemWrapaper>

        <ItemLine />

          <ItemWrapper>
            <IconImage src={icon1} loading="lazy"/>
            <TextWrapper>
              <ItemTitle>골프장 운행</ItemTitle>
              <ItemText>
                {`기업의 대표, 임원들과 접대를\n위한 골프장 운행 서비스`}
              </ItemText>
            </TextWrapper>
          </ItemWrapper>

          <ItemWrapper>
            <IconImage src={icon2} loading="lazy"/>
            <TextWrapper>
              <ItemTitle>장거리 운전 대행</ItemTitle>
              <ItemText>
                {`업의 행사나 장거리 운행에 
일일기사가 되어 드리는 서비스`}
              </ItemText>
            </TextWrapper>
          </ItemWrapper>

          <ItemWrapper>
            <IconImage src={icon3} loading="lazy"/>
            <TextWrapper>
              <ItemTitle>의전운행</ItemTitle>
              <ItemText>
                {`행사의 VIP혹은 기업의 대표,\n임원진들을 모시기 위한 의전운행`}
              </ItemText>
            </TextWrapper>
          </ItemWrapper>

      </IconItemWrapaper>

    </ThreeSectionContaianer>
  )
}


const ThreeSectionContaianer = styled.div`
  width: 100%;
  background-color: #195b90;
  padding-bottom: 5vw;
`

const ThreeHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const SectionTitleBar = styled.div`
  background-color: ${props => props.color};
  width: 6.38vw;
  height: 1.37vw;
`

const ThreeTitleSection = styled.div`
  margin-top: 38px;
`

const ShapIcon = styled.div`
  font-size: 5.93vw;
  font-weight: 300;
  letter-spacing: -2.05px;
  white-space: pre-wrap;
  color: #ffffff;
  font-family: NotoSansCJKkr_Light, serif;
`

const ThreeTitle = styled.div`
  font-size: 5.93vw;
  font-weight: 900;
  letter-spacing: -2.05px;
  color: #ffffff;
  font-family: NotoSansCJKkr_Black, serif;
`

const ThreeSubTitle = styled.div`
  font-size: 4.96vw;
  font-weight: 500;
  letter-spacing: -1.43px;
  color:  #ffffff;
  margin-left: 11.62vw;
  white-space: pre-wrap;
`

const IconItemWrapaper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: 10vw;
  padding-left: 9.85vw;
`

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 2;
  margin-bottom: -3vw;
`

const IconImage = styled.img`
  width: 37.13vw;
  z-index: 1;
`

const TextWrapper = styled.div`
 margin-bottom: 12vw;
  margin-left: -3.8vw;
`

const ItemText = styled.div`
  white-space: pre-wrap;
  text-align: left;
  font-size: 3.95vw;
  letter-spacing: -1.23px;
  font-family: NotoSansCJKkr_Regular, serif;
  color:  #ffffff;
`

const ItemLine = styled.div`
  width: 2px;
  height: 104.12vw;
  background-color: #ffffff;
  position: absolute;
  top: 2.60vw;
  left: 25vw;
`

const ItemTitle = styled.div`
  color: #a4ccfb;
  font-size: 4.96vw;
  font-weight: bold;
  letter-spacing: -1.43px;
  font-family: NotoSansCJKkr_Bold, serif;
`
