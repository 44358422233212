import styled from "styled-components";

// export const theme = {
//   colors: {
//     brandRed: '#E42313'
//   }
// }


// 1440px을 넘어가는 화면에서 비율 유지
// import {useIsDesktop} from "../src/hooks/useIsDesktop" 적용 해야 한다.
export const DesktopWrapper1440 = styled.div`
  width: ${(props) => props.isDesktop ? "100vw" : '1700px'};
`