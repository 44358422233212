import React from "react";
import styled from "styled-components";
import number03Image from "../../../../media/image/ContractInfo/UsageService/03.webp";
import mainImage from "../../../../media/image/ContractInfo/UsageService/shutterstock-2089227466.webp";
import {useIsDesktop} from "../../../../hooks/useIsDesktop";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export const UsageService = () => {
  const isDesktop = useIsDesktop();

  return(
    <>
      <AnimationOnScroll animateIn="animate__fadeIn" animatePreScroll={false} delay={2000} animateOnce={true}>
        <UsageServiceContainer isDesktop={isDesktop} className={'UsageService_trigger'} >
          <MainImage src={mainImage} alt={"main-image-03"} isDesktop={isDesktop} loading="lazy"/>

          <TextWrapper isDesktop={isDesktop}>
            <HeaderWrapper isDesktop={isDesktop}>
              <NumberImage src={number03Image} alt={"number-03-image"} isDesktop={isDesktop} loading="lazy"/>
              <Title isDesktop={isDesktop}>서비스 이용</Title>
            </HeaderWrapper>


            <TextBodyWrapper isDesktop={isDesktop}>
              <TextBody isDesktop={isDesktop}>➊ 국가대표 <span style={{fontWeight:'bold'}}>3000-3000</span> 또는 고객용 어플리케이션 콜 접수</TextBody>
              <TextBody isDesktop={isDesktop}>➋ 상담사의 출발지, 경유지, 도착지 등 확인, 요금안내 후 접수 완료</TextBody>
              <TextBody isDesktop={isDesktop}>➌ 국가대표 상황실에서 기사관리 시스템으로 자사기사 배차(지정, 다중 배차)</TextBody>
              <TextBody isDesktop={isDesktop}>➍ 국가대표 자사기사 확인 후 운행 시작(정장, 넥타이)</TextBody>
              <TextBody isDesktop={isDesktop}>➎ 목적지까지 안전 운행, 완벽 주차 마무리 후 전자서명</TextBody>
            </TextBodyWrapper>
          </TextWrapper>
        </UsageServiceContainer>
      </AnimationOnScroll>
    </>
  )
}

const UsageServiceContainer = styled.div`
  position: absolute;
  display: flex;
  top: ${props => props.isDesktop ? '47.09vw' : '843px'};
  left: ${props => props.isDesktop ? '6.98vw' : '125px'};
`

const MainImage = styled.img`
  margin-right: ${props => props.isDesktop ? '6.09vw' : '109px'};
  width: ${props => props.isDesktop ? '29.78vw' : '533px'};
  height: ${props => props.isDesktop ? '21.73vw' : '389px'};
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.isDesktop ? '0.95vw' : '17px'};
  padding-top: ${props => props.isDesktop ? '0.67vw' : '12px'};
`

const NumberImage = styled.img`
  width: ${props => props.isDesktop ? '6.65vw' : '119px'};
  margin-right: ${props => props.isDesktop ? '2.68vw' : '48px'};
`

const Title = styled.div`
  font-size: ${props => props.isDesktop ? '1.59vw' : '28px'};
  letter-spacing: -1.43px;
  font-weight: bold;
  font-family: NotoSansCJKkr_Bold, serif;
  color: #414042;
`

const TextWrapper = styled.div`
  margin-top: ${props => props.isDesktop ? '0.56vw' : '10px'};
`

const TextBodyWrapper = styled.div`
  margin-top: ${props => props.isDesktop ? '4.64vw' : '83px'};
`

const TextBody = styled.div`
  letter-spacing: -1.23px;
  font-size: ${props => props.isDesktop ? '1.37vw' : '25px'};
  line-height: 1.67;
  font-family: NotoSansCJKkr_Regular,serif;
  color: #414042;
  
`
