import React from "react";
import styled from "styled-components";
import OneSectionImage from '../../../../media/image/IntroJob/OneSection/sub-01@3x.webp'
import icon1 from '../../../../media/image/IntroJob/OneSection/icon-01.webp'
import icon2 from '../../../../media/image/IntroJob/OneSection/icon-02.webp'
import icon3 from '../../../../media/image/IntroJob/OneSection/icon-03.webp'
import icon4 from '../../../../media/image/IntroJob/OneSection/icon-04.webp'
import icon5 from '../../../../media/image/IntroJob/OneSection/icon-05.webp'
import {useIsDesktop} from "../../../../hooks/useIsDesktop";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export const OneSection = () => {
  const isDesktop = useIsDesktop();

  return(
    <>
      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animatePreScroll={false} animateOnce={true}>
      <OneSectionContainer isDesktop={isDesktop} >

        <AnimationOnScroll animateIn="animate__fadeIn" animatePreScroll={false} delay={1000} animateOnce={true}>
        <OneHeader isDesktop={isDesktop} >
          <OneTitleSection isDesktop={isDesktop}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <SectionTitleBar color={'#478cc3'} isDesktop={isDesktop}/>
              <ShapIcon isDesktop={isDesktop}>{`  # `}</ShapIcon>
              <OneTitle isDesktop={isDesktop}>법인대리운전</OneTitle>
            </div>
            <OneSubTitle isDesktop={isDesktop}>법인사업자 임원분들을 위한 프리미엄 서비스</OneSubTitle>
          </OneTitleSection>

          <OneTitleImage src={OneSectionImage} alt={'one-title-image'} isDesktop={isDesktop} loading="lazy"/>
        </OneHeader>

        <IconItemWrapaper isDesktop={isDesktop}  >

          <ItemLine isDesktop={isDesktop} />

          <div style={{display:"flex", justifyContent:"space-between", width: '80%'}}>

            <ItemWrapper isDesktop={isDesktop}>
              <IconImage src={icon1} isDesktop={isDesktop} loading="lazy"/>
              <ItemText isDesktop={isDesktop}>
                {`국내 최초, 최대 
법인전문 운전대행
서비스의 노하우`}
              </ItemText>
            </ItemWrapper>

            <ItemWrapper isDesktop={isDesktop}>
              <IconImage src={icon2} isDesktop={isDesktop} loading="lazy"/>
              <ItemText isDesktop={isDesktop}>
                {`월 단위의 편리한
후불 결제 및 세금계산서 발행
비용처리`}
              </ItemText>
            </ItemWrapper>

            <ItemWrapper isDesktop={isDesktop}>
              <IconImage src={icon3} isDesktop={isDesktop} loading="lazy"/>
              <ItemText isDesktop={isDesktop}>
                {`Prime-Time때
자사 베테랑 기사를
최우선 배정`}
              </ItemText>
            </ItemWrapper>

            <ItemWrapper isDesktop={isDesktop}>
              <IconImage src={icon4} isDesktop={isDesktop} loading="lazy"/>
              <ItemText isDesktop={isDesktop}>
                {`중요 바이어 대상
철도역, 공항 마중 전용기사 및
통역`}
              </ItemText>
            </ItemWrapper>

            <ItemWrapper isDesktop={isDesktop}>
              <IconImage src={icon5} isDesktop={isDesktop} loading="lazy"/>
              <ItemText isDesktop={isDesktop}>
                {`골프장 운행, 지방 출장 및
VIP 의전`}
              </ItemText>
            </ItemWrapper>

          </div>
        </IconItemWrapaper>
        </AnimationOnScroll>
      </OneSectionContainer>
      </AnimationOnScroll>
    </>
  )
}

const OneSectionContainer = styled.div`
  width: 100%;
  height: ${props => props.isDesktop ? '30.17vw' : '540px'};
  background-color: #e6e7e8;
`

const SectionTitleBar = styled.div`
  background-color: ${props => props.color};
  width: ${props => props.isDesktop ? '5.25vw' : '94px'};
  height: ${props => props.isDesktop ? '0.61vw' : '11px'};
`

const OneHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${props => props.isDesktop ? '1.96vw' : '35px'};
`

const OneTitleSection = styled.div`
  margin-top: ${props => props.isDesktop ? '2.12vw' : '38px'};
`

const ShapIcon = styled.div`
  font-size: ${props => props.isDesktop ? '2.29vw' : '41px'};
  font-weight: 300;
  letter-spacing: -2.05px;
  white-space: pre-wrap;
  color: #3987c5;
  font-family: NotoSansCJKkr_Light, serif;
`

const OneTitle = styled.div`
  font-size: ${props => props.isDesktop ? '2.29vw' : '41px'};
  font-weight: 900;
  letter-spacing: -2.05px;
  color: #3987c5;
  font-family: NotoSansCJKkr_Black, serif;
`

const OneSubTitle = styled.div`
  font-size: ${props => props.isDesktop ? '1.59vw' : '28.5px'};
  font-weight: 500;
  letter-spacing: -1.43px;
  color: #414042;
  margin-left: ${props => props.isDesktop ? '7.65vw' : '137px'};
`

const OneTitleImage = styled.img`
  width: ${props => props.isDesktop ? '23.58vw' : '422px'};
  margin-right: ${props => props.isDesktop ? '4.13vw' : '74px'};
`

const IconItemWrapaper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: ${props => props.isDesktop ? '2.12vw' : '38px'};
`

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
`

const IconImage = styled.img`
  margin-left: ${props => props.isDesktop ? '1.68vw' : '30px'};
  width: ${props => props.isDesktop ? '8.55vw' : '153px'};
`

const ItemText = styled.div`
  white-space: pre-wrap;
  text-align: center;
  margin-top: ${props => props.isDesktop ? '-1.40vw' : '-25px'};
  font-size: ${props => props.isDesktop ? '1.37vw' : '24.5px'};
  letter-spacing: -1.23px;
  font-family: NotoSansCJKkr_Regular, serif;
  color: #414042;;
`

const ItemLine = styled.div`
  width: 70%;
  height: ${props => props.isDesktop ? '0.11vw' : '2px'};
  background-color: #3987c5;
  position: absolute;
  top: ${props => props.isDesktop ? '3.63vw' : '65px'};
`