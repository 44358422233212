import React from "react";
import styled from "styled-components";
import image1 from "../../media/mobileImage/TopMain/mainTop1-m.jpg"
import image2 from "../../media/mobileImage/TopMain/mainTop2-m.jpg"
import menuIcon from '../../media/mobileImage/TopMain/invalid-name.webp'
import AppDownImage from '../../media/mobileImage/TopMain/AppDownImage.PNG'
import Scroll from 'react-scroll';
import {useSetRecoilState} from "recoil";
import {DrawerVisibleAtom} from "../../recoil";
import {Carousel} from 'react-responsive-carousel';
import { isIOS } from 'react-device-detect';

export const TopMainMobile = () => {
    const setDrawerVisible = useSetRecoilState(DrawerVisibleAtom)
    let Element = Scroll.Element;

    return (
        <a href={isIOS ? 'https://apps.apple.com/kr/app/3000-3000-%EB%8C%80%EB%A6%AC%EC%9A%B4%EC%A0%84/id1662041820' : 'https://play.google.com/store/apps/details?id=com.logisoft.logiapp.smartcall_38047&pli=1'}>
            <Element name={'TopMain-Scroll'}>
                <TopMainContainer>
                    <MenuIcon src={menuIcon} onClick={() => setDrawerVisible(true)}/>
                    <Carousel autoPlay={true} infiniteLoop={true} interval={5000} showArrows={false} showStatus={false}
                              swipeable={false}>
                        <div>
                            <ImageWrapper alt={'top-main-img1'} src={image1} loading="lazy"/>
                        </div>
                        <div>
                            <ImageWrapper alt={'top-main-img1'} src={image2} loading="lazy"/>
                        </div>
                    </Carousel>
                    <DownloadAppImage src={AppDownImage} loading="lazy"/>
                </TopMainContainer>
            </Element>
        </a>
    )
}


const TopMainContainer = styled.div`
  margin-bottom: -54px;
  overflow: hidden;
  position: relative;
`

const ImageWrapper = styled.img`
  //height: 100vh;
  width: 100vw;
`

const MenuIcon = styled.img`
  position: fixed;
  width: 13.57vw;
  right: 3.71vw;
  top: 3.47vw;
  cursor: pointer;
  z-index: 999;
`

const DownloadAppImage = styled.img`
  position: absolute;
  z-index: 999;
  width: 43vw;
  bottom: 35%;
  right: 4vw;
  background-color: rgba(1, 1, 1, 0.5);
  border-radius: 7px;
`