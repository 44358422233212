import React from "react";
import styled from "styled-components";
import icon1 from '../../../../media/image/IntroJob/ThreeSection/icon-01.webp'
import icon2 from '../../../../media/image/IntroJob/ThreeSection/icon-02.webp'
import icon3 from '../../../../media/image/IntroJob/ThreeSection/icon-03.webp'
import {useIsDesktop} from "../../../../hooks/useIsDesktop";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export const ThreeSection = () => {
  const isDesktop = useIsDesktop();

  return(
    <AnimationOnScroll animateIn="animate__fadeInLeftBig" animatePreScroll={false} animateOnce={true}>
    <ThreeSectionContaianer isDesktop={isDesktop} >

      <AnimationOnScroll animateIn="animate__fadeIn" animatePreScroll={false} delay={1000} animateOnce={true}>
      <ThreeHeader isDesktop={isDesktop}  >
        <ThreeTitleSection isDesktop={isDesktop}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <SectionTitleBar color={'#b4bac1'} isDesktop={isDesktop}/>
            <ShapIcon isDesktop={isDesktop}>{`  # `}</ShapIcon>
            <ThreeTitle isDesktop={isDesktop}>일일기사대행</ThreeTitle>
          </div>
          <ThreeSubTitle isDesktop={isDesktop}>합리적인 서비스 경험, 국가대표가 빠르고 안전하게 모십니다</ThreeSubTitle>
        </ThreeTitleSection>
      </ThreeHeader>

      <IconItemWrapaper isDesktop={isDesktop}  >

        <ItemLine isDesktop={isDesktop} />

        <div style={{display:"flex", justifyContent:"space-between", width: '80%'}}>

          <ItemWrapper isDesktop={isDesktop}>
            <ItemTitle isDesktop={isDesktop}>골프장 운행</ItemTitle>
            <IconImage src={icon1} isDesktop={isDesktop} loading="lazy"/>
            <ItemText isDesktop={isDesktop}>
              {`기업의 대표, 임원들과 접대를 위한 
골프장 운행 서비스`}
            </ItemText>
          </ItemWrapper>

          <ItemWrapper isDesktop={isDesktop}>
            <ItemTitle isDesktop={isDesktop}>장거리 운전 대행</ItemTitle>
            <IconImage src={icon2} isDesktop={isDesktop} loading="lazy"/>
            <ItemText isDesktop={isDesktop}>
              {`업의 행사나 장거리 운행에 
일일기사가 되어 드리는 서비스`}
            </ItemText>
          </ItemWrapper>

          <ItemWrapper isDesktop={isDesktop}>
            <ItemTitle isDesktop={isDesktop}>의전운행</ItemTitle>
            <IconImage src={icon3} isDesktop={isDesktop} loading="lazy"/>
            <ItemText isDesktop={isDesktop}>
              {`행사의 VIP혹은 기업의 대표, 임원진들을 
모시기 위한 의전운행`}
            </ItemText>
          </ItemWrapper>

        </div>
      </IconItemWrapaper>
        </AnimationOnScroll>

    </ThreeSectionContaianer>
    </AnimationOnScroll>
  )
}


const ThreeSectionContaianer = styled.div`
  width: 100%;
  background-color: #195b90;
  padding-top: ${props => props.isDesktop ? '1.96vw' : '35px'};
  padding-bottom: ${props => props.isDesktop ? '5.03vw' : '90px'};
`

const ThreeHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const SectionTitleBar = styled.div`
  background-color: ${props => props.color};
  width: ${props => props.isDesktop ? '5.25vw' : '94px'};
  height: ${props => props.isDesktop ? '0.61vw' : '11px'};
`

const ThreeTitleSection = styled.div`
  margin-top: ${props => props.isDesktop ? '2.12vw' : '38px'};
`

const ShapIcon = styled.div`
  font-size: ${props => props.isDesktop ? '2.29vw' : '41px'};
  font-weight: 300;
  letter-spacing: -2.05px;
  white-space: pre-wrap;
  color: #ffffff;
  font-family: NotoSansCJKkr_Light, serif;
`

const ThreeTitle = styled.div`
  font-size: ${props => props.isDesktop ? '2.29vw' : '41px'};
  font-weight: 900;
  letter-spacing: -2.05px;
  color: #ffffff;
  font-family: NotoSansCJKkr_Black, serif;
`

const ThreeSubTitle = styled.div`
  font-size: ${props => props.isDesktop ? '1.59vw' : '28.5px'};
  font-weight: 500;
  letter-spacing: -1.43px;
  color:  #ffffff;
  margin-left: ${props => props.isDesktop ? '7.65vw' : '137px'};
`

const IconItemWrapaper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: ${props => props.isDesktop ? '5.08vw' : '91px'};
`

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  width: 33%;
`

const IconImage = styled.img`
  margin-left: ${props => props.isDesktop ? '1.68vw' : '30px'};
  width: ${props => props.isDesktop ? '8.55vw' : '153px'};
`

const ItemText = styled.div`
  white-space: pre-wrap;
  text-align: center;
  margin-top: ${props => props.isDesktop ? '-1.40vw' : '-25px'};
  font-size: ${props => props.isDesktop ? '1.37vw' : '24.5px'};
  letter-spacing: -1.23px;
  font-family: NotoSansCJKkr_Regular, serif;
  color:  #ffffff;
  
`

const ItemLine = styled.div`
  width: 50%;
  height: 2px;
  background-color: #ffffff;
  position: absolute;
  top: ${props => props.isDesktop ? '6.65vw' : '119px'};
`

const ItemTitle = styled.div`
  color: #a4ccfb;
  font-size: ${props => props.isDesktop ? '1.59vw' : '28.5px'};
  font-weight: bold;
  letter-spacing: -1.43px;
  margin-bottom: ${props => props.isDesktop ? '0.95vw' : '17px'};
  font-family: NotoSansCJKkr_Bold, serif;
`
