import React from "react";
import styled from "styled-components";
import TwoSectionImage from '../../../../../media/mobileImage/IntroJob/sub-02.webp'
import icon1 from "../../../../../media/mobileImage/IntroJob/TwoSectionMobile/icon-01.webp";
import icon2 from "../../../../../media/mobileImage/IntroJob/TwoSectionMobile/icon-02.webp";
import icon3 from "../../../../../media/mobileImage/IntroJob/TwoSectionMobile/icon-03.webp";
import icon4 from "../../../../../media/mobileImage/IntroJob/TwoSectionMobile/icon-04.webp";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export const TwoSectionMobile = () => {
  return(
    <>
    <TwoSectionContainer>
      <TwoHeader>
        <TwoTitleSection>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <SectionTitleBar color={'#093b64'} />
            <ShapIcon>{`  # `}</ShapIcon>
            <TwoTitle>3000-3000 콜서비스</TwoTitle>
          </div>
          <TwoSubTitle>{`합리적인 서비스 경험, 국가대표가 빠르고 
안전하게 모십니다`}</TwoSubTitle>
        </TwoTitleSection>
      </TwoHeader>

      <div style={{display:"flex", justifyContent:'center', padding: '7.75vw 0'}}>
      <TwoTitleImage src={TwoSectionImage} alt={'two-title-image'} loading="lazy"/>
      </div>
    </TwoSectionContainer>

      <AnimationOnScroll animateIn="animate__fadeIn" animatePreScroll={false} animateOnce={true}>
      <IconItemWrapaper>

          <ItemWrapper>
            <IconImage src={icon1} loading="lazy"/>
            <ItemText>
              {`직원 분들을 위하여 비용의 10~30%까지\n가격을 낮춘 혁신적이고 합리적인 서비스\n`}
              <ItemSpan>
                {'서울/경기/수도권 15,000부터'}
              </ItemSpan>
            </ItemText>
          </ItemWrapper>

          <ItemWrapper>
            <IconImage src={icon2} loading="lazy"/>
            <ItemText>
              {` 운행은 먼저, 결제는 후불로 편하게 이용 가능하며 
자사 기사 우선 배치 지연 시 연합기사 후속 배치`}
            </ItemText>
          </ItemWrapper>

          <ItemWrapper>
            <IconImage src={icon3} loading="lazy"/>
            <ItemText>
              {` 현 3만여개 추산 대리운전 업체 중
국가대표는 20년 전통의 법인전문 운전대행 서비스 회사로
특별한 시스템과 노하우로 특화된 서비스 제공`}
            </ItemText>
          </ItemWrapper>

          <ItemWrapper>
            <IconImage src={icon4} loading="lazy"/>
            <ItemText>
              {`법인 고객 중 직원명단 등록 후 이용시
기본 서비스 외 입원비 보상, 렌터카, 법규위반 보상등 
다양한 혜택 제공`}
            </ItemText>
          </ItemWrapper>

      </IconItemWrapaper>
      </AnimationOnScroll>
    </>
  )
}



const TwoSectionContainer = styled.div`
  width: 100%;
  background-color: #b4bac1;
`

const TwoHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const SectionTitleBar = styled.div`
  background-color: ${props => props.color};
  width: 6.38vw;
  height: 1.37vw;
`

const TwoTitleSection = styled.div`
  margin-top: 38px;
`

const ShapIcon = styled.div`
  font-size: 5.93vw;
  font-weight: 300;
  letter-spacing: -2.05px;
  white-space: pre-wrap;
  color: #3987c5;
  font-family: NotoSansCJKkr_Light, serif;
`

const TwoTitle = styled.div`
  font-size: 5.93vw;
  font-weight: 900;
  letter-spacing: -2.05px;
  color: #3987c5;
  font-family: NotoSansCJKkr_Black, serif;
`

const TwoSubTitle = styled.div`
  font-size: 4.96vw;
  font-weight: 500;
  letter-spacing: -1.43px;
  color: #414042;
  margin-left: 11.62vw;
  white-space: pre-wrap;
`

const TwoTitleImage = styled.img`
  width: 78.3vw;
`

const IconItemWrapaper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 7vw;
  margin-bottom: 5vw;
`

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 7vw;
`

const IconImage = styled.img`
  margin-left: 7vw;
  width: 33.41vw;
  margin-bottom: -1vw;
`

const ItemText = styled.div`
  white-space: pre-wrap;
  text-align: center;
  margin-top: -25px;
  font-size: 3.95vw;
  letter-spacing: -1.23px;
  font-family: NotoSansCJKkr_Regular, serif;
  color: #414042;;
`

const ItemSpan = styled.span`
  font-size: 0.73em;
  letter-spacing: -0.82px;
  font-family: NotoSansCJKkr_Bold, serif;
  color: #414042;
`