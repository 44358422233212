import React from "react";
import {Drawer} from 'antd';
import {useRecoilState} from "recoil";
import {DrawerVisibleAtom} from "../../recoil";
import styled from "styled-components";
import Scroll from "react-scroll";

export const SideNav = () => {
  const [drawerVisible, setDrawerVisible] = useRecoilState(DrawerVisibleAtom);
  let scroller = Scroll.scroller;

  const onClose = () => {
    setDrawerVisible(false);
  }

  return (
    <Drawer
      title={''}
      placement={'right'}
      closable={false}
      onClose={onClose}
      visible={drawerVisible}
      key={'Right-Drawer'}
      contentWrapperStyle={{width: '60%'}}
      drawerStyle={{backgroundColor: 'rgba(255, 255, 255, 0.9)'}}
      headerStyle={{display: 'none'}}
    >

      <NavList>
        <LinkLabel
          onClick={() => {
            setDrawerVisible(false)
            scroller.scrollTo("TopMain-Scroll", {
              duration: 600,
              smooth: true,
              offset: 0,
            })
          }}>
          Home
        </LinkLabel>

        <LinkLabel
          onClick={() => {
            setDrawerVisible(false)
            scroller.scrollTo("IntroCompany-Scroll", {
              duration: 800,
              smooth: true,
              offset: 0,
            })
          }}>
          회사소개
        </LinkLabel>

        <LinkLabel
          onClick={() => {
            setDrawerVisible(false)
            scroller.scrollTo("IntroJob-Scroll", {
              duration: 800,
              smooth: true,
              offset: 0,
            })
          }}>
          사업소개
        </LinkLabel>

        <LinkLabel
          onClick={() => {
            setDrawerVisible(false)
            scroller.scrollTo("ContractInfo-Scroll", {
              duration: 800,
              smooth: true,
              offset: 0,
            })
          }}>
          계약안내
        </LinkLabel>

      </NavList>

    </Drawer>
  )
}

const NavList = styled.div`
  display: flex;
  flex-direction: column;
`

const LinkLabel = styled.div`
  font-family: "NotoSansCJKkr_Bold", serif;
  font-size: 6vw;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  padding: 4vw 0;
`