import React from "react";
import styled from "styled-components";
import {OneSectionMobile} from "../Desktop/blocks/IntroJob/mobile/OneSectionMobile";
import {TwoSectionMobile} from "../Desktop/blocks/IntroJob/mobile/TwoSectionMobile";
import {ThreeSectionMobile} from "../Desktop/blocks/IntroJob/mobile/ThreeSectionMobile";
import Scroll from 'react-scroll';


export const IntroJobMobile = () => {
  let Element = Scroll.Element;

  return (
    <Element name={'IntroJob-Scroll'}>
      <IntroJobContainer>

        <TitleWrapper>
          <Title>
            사업소개
          </Title>
        </TitleWrapper>

        <OneSectionMobile />

        <TwoSectionMobile />

        <ThreeSectionMobile />

      </IntroJobContainer>
    </Element>
  )
}

const IntroJobContainer = styled.div`
  background-color: white;
  padding-top: 14.74vw;
  overflow: hidden;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px;
`

const Title = styled.div`
  font-size: 9.93vw;
  padding-top: 9px;
  letter-spacing: -2.05px;
  color: #414042;
  font-family: NotoSansCJKkr_Bold sans-serif;
  font-weight: bold;
`


