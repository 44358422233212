import React from "react";
import styled from "styled-components";
import titleLogo from "../../media/image/IntroCompany/dingbat@3x.webp";
import {OneSection} from "./blocks/IntroJob/OneSection";
import {TwoSection} from "./blocks/IntroJob/TwoSection";
import {ThreeSection} from "./blocks/IntroJob/ThreeSection";
import Scroll from 'react-scroll';
import {useIsDesktop} from "../../hooks/useIsDesktop";

export const IntroJob = () => {
  let Element = Scroll.Element;
  const isDesktop = useIsDesktop();

  return (
    <Element name={'IntroJob-Scroll'}>
      <IntroJobContainer isDesktop={isDesktop}>

        <TitleWrapper isDesktop={isDesktop}>
          <TitleImage src={titleLogo} alt={'title-logo'} isDesktop={isDesktop} loading="lazy"/>
          <Title isDesktop={isDesktop}>
            사업소개
          </Title>
        </TitleWrapper>

        <OneSection />

        <TwoSection />

        <ThreeSection />

      </IntroJobContainer>
    </Element>
  )
}

const IntroJobContainer = styled.div`
  background-color: white;
  margin-top: -3px;
  padding-top: ${props => props.isDesktop ? '11.62vw' : '208px'};
`
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${props => props.isDesktop ? '3.69vw' : '66px'};
  margin-bottom: ${props => props.isDesktop ? '2.01vw' : '36px'};
`

const TitleImage = styled.img`
  width: ${props => props.isDesktop ? '1.23vw' : '22px'};
  margin-right: 10px;
`

const Title = styled.div`
  font-size: ${props => props.isDesktop ? '2.29vw' : '41px'};
  padding-top: ${props => props.isDesktop ? '0.50vw' : '9px'};
  letter-spacing: -2.05px;
  color: #414042;
  font-family: NotoSansCJKkr_Bold sans-serif;
  font-weight: bold;
`


