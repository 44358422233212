import {DesktopWrapper1440} from "./styles/GlobalStyledComponents";
import {useIsDesktop} from "./hooks/useIsDesktop"
import {useIsMobile} from "./hooks/useIsMobile"
import {TopMain} from "./components/Desktop/TopMain";
import styled from "styled-components";
import {ShareHeader} from "./components/Desktop/ShareHeader";
import {IntroCompany} from "./components/Desktop/IntroCompany";
import {IntroJob} from "./components/Desktop/IntroJob";
import {ContractInfo} from "./components/Desktop/ContractInfo";
import {ShareFooter} from "./components/Desktop/ShareFooter";
import {TopMainMobile} from "./components/Mobile/TopMainMobile";
import {IntroCompanyMobile} from "./components/Mobile/IntroCompanyMobile";
import {IntroJobMobile} from "./components/Mobile/IntroJobMobile";
import {ContractInfoMobile} from "./components/Mobile/ContractInfoMobile";
import {SideNav} from "./components/Desktop/SideNav";
import {ShareFooterMobile} from "./components/Mobile/ShareFooterMobile";
import youtubeIcon from "./media/mobileImage/ShareFooter/youtube-m.webp";
import facebookIcon from "./media/mobileImage/ShareFooter/fb-m.webp";
import instaIcon from "./media/mobileImage/ShareFooter/insgta-m.webp";
import React from "react";

const App = () => {
  const isDesktop = useIsDesktop();

  return (
    <GlobalDiv className="App">
      <DesktopWrapper1440 isDesktop={isDesktop} useIsMobile={useIsMobile()}>
        {useIsMobile() ? <TopMainMobile /> : <TopMain />}

        {useIsMobile() ? <SideNav /> : <ShareHeader />}

        {useIsMobile() ? <IntroCompanyMobile /> : <IntroCompany />}

        {useIsMobile() ? <IntroJobMobile /> : <IntroJob />}

        {useIsMobile() ? <ContractInfoMobile /> : <ContractInfo />}

        {useIsMobile() &&
          <SocialLogoWrapper>
            <SocailLogoImage src={youtubeIcon} />
            <SocailLogoImage src={facebookIcon} />
            <SocailLogoImage src={instaIcon} />
          </SocialLogoWrapper>
        }

        {useIsMobile() ? <ShareFooterMobile /> : <ShareFooter />}

      </DesktopWrapper1440>
    </GlobalDiv>
  );
}

export default App;


const GlobalDiv = styled.div`
  background-color: #d6d6d6;
  display: flex;
  justify-content: center;
`

const SocialLogoWrapper = styled.div`
  background-color: white;
  padding-top: 10vw;
  display: flex;
  justify-content: center;
  padding-bottom: 3.5vw;
`

const SocailLogoImage = styled.img`
  width: 8.46vw;
  height: 8.46vw;
  margin-left: 5.49vw;
  cursor: pointer;
`