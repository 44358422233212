import React from "react";
import styled from "styled-components";
import number05Image from "../../../../../media/image/ContractInfo/Payment/05.webp";
import main05Image from "../../../../../media/image/ContractInfo/Payment/shutterstock-635097344.webp";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export const PaymentMobile = () => {
  return(
    <AnimationOnScroll animateIn="animate__fadeIn" animatePreScroll={false} animateOnce={true}>
    <PaymentContainer>
      <HeaderWrapper>
        <NumberImage src={number05Image} alt={"number-01-image"} loading="lazy"/>
        <Title>결제</Title>
      </HeaderWrapper>

      <ImageWrapper>
        <RowImageWrapper>
          <MainImage src={main05Image} />

          {/*<BankAccountWrapper>*/}
          {/*  <BankAccount>국민은행 : 063301-04-115568</BankAccount>*/}
          {/*  <BankAccount>기업은행 :  220-031146-04-010</BankAccount>*/}
          {/*  <BankAccount>우리은행 : 418-068635-13-101</BankAccount>*/}
          {/*  <BankAccount>신한은행 : 100-028-138243</BankAccount>*/}
          {/*  <BankAccount>하나은행 : 176-910006-86504</BankAccount>*/}
          {/*</BankAccountWrapper>*/}
        </RowImageWrapper>
        <ImageText>➊ 신용협동조합 : 131-021-834882</ImageText>
        <ImageText>➋ 계산서 수령 후 입금</ImageText>
      </ImageWrapper>

    </PaymentContainer>
    </AnimationOnScroll>
  )
}

const PaymentContainer = styled.div`
  margin-top: 10vw;
  margin-right:  1.94vw;
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-bottom: 15.98vw;
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.95vw;
  width: 85.55vw;
`

const NumberImage = styled.img`
  width: 15.82vw;
  margin-right: 5.65vw;
`

const Title = styled.div`
  font-size: 4.64vw;
  letter-spacing: -1.43px;
  font-weight: bold;
  font-family: NotoSansCJKkr_Bold, serif;
  color: #414042;
`

const ImageWrapper = styled.div`

`

const MainImage = styled.img`
  width: 85.55vw;
  margin-bottom: 0.84vw;
`

const ImageText = styled.div`
  font-size: 3.95vw;
  letter-spacing: -1.23px;
  color: #414042;
  font-family: NotoSansCJKkr_Regular,serif;
`

const RowImageWrapper = styled.div`
  display: flex;
`

// const BankAccountWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: end;
//   padding-bottom: 0.56vw;
//   margin-left: 1.34vw;
// `
//
// const BankAccount = styled.div`
//   font-size: 1.37vw;
//   letter-spacing: -1.23px;
//   color: #414042;
//   font-family: NotoSansCJKkr_Regular,serif;
// `
