import React from "react";
import styled from "styled-components";
import holderImage from '../../../../media/image/ContractInfo/holder.webp'
import {PhoneService} from "./PhoneService";
import {Contract} from "./Contract";
import {UsageService} from "./UsageService";
import {PublishTex} from "./PublishTex";
import {Payment} from "./Payment";
import {useIsDesktop} from "../../../../hooks/useIsDesktop";

export const HolderPaper = () => {
  const isDesktop = useIsDesktop();
  return(
    <HolderPaperContainer>
      <HolderImage src={holderImage} alt={'holder-image'} isDesktop={isDesktop}/>

      <PhoneService />

      <Contract />

      <UsageService />

      <PublishTex />

      <Payment />

    </HolderPaperContainer>
  )
}

const HolderPaperContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 40px;
  position: relative;
`

const HolderImage = styled.img`
  width:  ${props => props.isDesktop ? '89.83vw' : '1608px'};
`