import React from "react";
import styled from "styled-components";
import number01Image from '../../../../../media/image/ContractInfo/PhoneService/01.webp'
import main01Image from '../../../../../media/mobileImage/ContractInfo/PhoneService/shutterstock-1913637745.webp'
import { AnimationOnScroll } from 'react-animation-on-scroll';

export const PhoneServiceMobile = () => {
  return(
    <AnimationOnScroll animateIn="animate__fadeIn" animatePreScroll={false} animateOnce={true}>
    <PhoneServiceContainer>
      <HeaderWrapper>
        <NumberImage src={number01Image} alt={"number-01-image"} loading="lazy"/>
        <Title>전화상담</Title>
      </HeaderWrapper>

      <div style={{display:"flex"}}>
        <div>
          <MainImage src={main01Image} loading="lazy"/>

          <SubTitle>서비스 및 법인 계약 문의 : <TextSpan style={{}}>080-132-3000</TextSpan></SubTitle>
        </div>

        <div style={{position:"relative", display: "flex", alignItems: "end"}}>
          <OnlineService>{`온라인\n상담 신청`}</OnlineService>
        </div>
      </div>
    </PhoneServiceContainer>
    </AnimationOnScroll>
  )
}

const PhoneServiceContainer = styled.div`

`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3vw;
`

const NumberImage = styled.img`
  width: 15.82vw;
  margin-right: 5.65vw;
`

const Title = styled.div`
  font-size: 4.64vw;
  letter-spacing: -1.43px;
  font-weight: bold;
  font-family: NotoSansCJKkr_Bold, serif;
  color: #414042;
`

const MainImage = styled.img`
  width: 61.34vw;
  margin-right: 1.53vw;
`

const SubTitle = styled.div`
  font-size:  3.55vw;
  font-family: NotoSansCJKkr_Regular,serif;
  letter-spacing: -1.33px;
  color: #414042;
  margin-top: 1.07vw;
`

const OnlineService = styled.div`
  text-align: center;
  background-color: #1b75bc;
  width: 33.98vw;
  height: 19.94vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 4.64vw;
  letter-spacing: -1.23px;
  font-family: NotoSansCJKkr_Bold,serif;
  white-space: pre-wrap;
  position: relative;
  bottom: 6.6vw;
`

const TextSpan = styled.span`
  color: #195b90;
  font-family: NotoSansCJKkr_Bold,serif;
  letter-spacing: -1.32px;
  font-weight: bold;
`