import React from "react";
import styled from "styled-components";
import Scroll from 'react-scroll';
import twentyImage from '../../media/mobileImage/IntroCompany/lead.webp'
import logo1 from '../../media/image/IntroCompany/icon-1.webp'
import logo2 from '../../media/image/IntroCompany/icon-2.webp'
import logo3 from '../../media/image/IntroCompany/icon-3.webp'
import logo4 from '../../media/image/IntroCompany/icon-4.webp'
import check from '../../media/image/IntroCompany/check.webp'
import backImage from '../../media/mobileImage/IntroCompany/sub-bg.png'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import {Parallax} from 'react-scroll-parallax';

export const IntroCompanyMobile = () => {
  let Element = Scroll.Element;

  return(
    <Element name={'IntroCompany-Scroll'}>
    <IntroComContainer>

      <TwentyWrapper>
        <TwentyImage src={twentyImage} alt={'twenty-image'} loading="lazy"/>
        <TwentyText>
          {`20년이라는 짧지 않은 시간동안
고객님과 함께 해온 국가대표 콜 서비스는
그 시간의 무게만큼 우리 업에 대한 책무를 느낍니다.
우리의 어깨가 무거울수록
보다 안전하고 평안한 여정이 된다고 믿기에
오늘도 우리는강한 책임감으로 고객님을 모십니다`}
        </TwentyText>
      </TwentyWrapper>

      <SideWrapper>
        <AnimationOnScroll animateIn="animate__backInLeft" animatePreScroll={false} animateOnce={true}>
            <LeftSideTextWrapper>
              <div style={{display:"flex", flexDirection:"column",  marginRight: '2.42vw'}}>
                <SideTitle>
                  Standard
                </SideTitle>
                <SideSubTitle>
                  업계의 새로운 기준을 만들어 갑니다
                </SideSubTitle>
                <SideBodyText>
                  단순한 운전기사의 연결이 아닌 책임감을 갖고 사업을 이어갑니다
                </SideBodyText>
              </div>
              <IconImage src={logo1} loading="lazy"/>
            </LeftSideTextWrapper>
        </AnimationOnScroll>

        <AnimationOnScroll animateIn="animate__backInRight" animatePreScroll={false} animateOnce={true}>
        <RightSideTextWrapper>
          <IconImage src={logo2} loading="lazy"/>
          <div style={{display:"flex", flexDirection:"column",  marginLeft: '2.42vw'}}>
          <SideTitle>
            Confidence
          </SideTitle>
          <SideSubTitle>
            어떤 상황에도 믿을 수 있습니다
          </SideSubTitle>
          <SideBodyText>
            첫 배차에서 마지막 주차까지 책임감을 갖고 고객을 대합니다
            고객과 기사간의 신뢰의 관계를 만들어가고 있습니다
          </SideBodyText>
          </div>
        </RightSideTextWrapper>
        </AnimationOnScroll>

        <AnimationOnScroll animateIn="animate__backInLeft" animatePreScroll={false} animateOnce={true}>
          <LeftSideTextWrapper>
            <div style={{display:"flex", flexDirection:"column",  marginRight: '2.42vw'}}>
            <SideTitle>
              Responsibility
            </SideTitle>
            <SideSubTitle>
              할수 있는 모든것을 책임지겠습니다
            </SideSubTitle>
            <SideBodyText>
              사고가 날 수는 있지만 사고초리에 대한 불만은 있을 수 없는 일입니다
              그리고 그것이 책임감이라고 믿고 있습니다
            </SideBodyText>
            </div>
            <IconImage src={logo3} />
          </LeftSideTextWrapper>
        </AnimationOnScroll>

          <AnimationOnScroll animateIn="animate__backInRight" animatePreScroll={false} animateOnce={true}>
          <RightSideTextWrapper>
            <IconImage src={logo4} />
            <div style={{display:"flex", flexDirection:"column", marginLeft: '2.42vw'}}>
            <SideTitle>
              Security
            </SideTitle>
            <SideSubTitle>
              고객의 정보까지도 보호합니다
            </SideSubTitle>
            <SideBodyText>
              법인 고객의 개인정보 및 영업비밀에  대한 중요성을 인지하고
              보안유지를 서약합니다
            </SideBodyText>
            </div>
          </RightSideTextWrapper>
          </AnimationOnScroll>
      </SideWrapper>

    </IntroComContainer>

      <BottomImageWrapper>
        <Parallax speed={8}>
        <BottomCheckImage src={check} alt={'check-image'} loading="lazy"/>
        <div style={{overflow:"hidden", display:'flex', justifyContent:'center', alignItems:'center' }}>
          <BottomBackImage src={backImage} alt={'bottom-back-image'} loading="lazy"/>
        </div>
        </Parallax>
      </BottomImageWrapper>
    </Element>
  )
}



// ---------------------------------Style Side----------------------------------

const IntroComContainer = styled.div`
  background-color: white;
  padding-top: 1.14vw;
  padding-left: 5.25vw;
  padding-right: 5.25vw;
  padding-bottom: 22.20vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const TwentyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16.54vw;
`

const TwentyImage = styled.img`
  width: 62.55vw;
  margin-bottom: 2.36vw;
`

const TwentyText = styled.div`
  font-size: 4.08vw;
  letter-spacing: -1.23px;
  text-align: center;
  font-family: NotoSansCJKkr_Medium,serif ;
  margin-top: 2.10vw;
  //white-space: pre-wrap;
`


const SideWrapper = styled.div`
  margin-top: 34px;
  //display: flex;
  //justify-content: center;
`


const IconImage = styled.img`
  width: 19.05vw;
  height: 19.05vw;
`

const LeftSideTextWrapper = styled.div`
  text-align: right;
  display: flex;
  align-items: center;
  margin-bottom: 5.57vw;
`

const RightSideTextWrapper = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 5.57vw;
`

const SideTitle = styled.div`
  font-size: 9.89vw;
  font-family: NotoSansCJKkr_Regular,serif ;
  line-height: 1.43;
  letter-spacing: -1.22px;
  color: #e6e7e8;
  font-style: italic;
`

const SideSubTitle = styled.div`
  font-size: 4.72vw;
  font-weight: 500;
  letter-spacing: -0.89px;
  font-family: NotoSansCJKkr_Medium,serif ;
`

const SideBodyText = styled.div`
  font-size: 3.79vw;
  font-family: NotoSansCJKkr_DemiLight,serif;
  line-height: 1.26;
  letter-spacing: -0.71px;
  color: #6d6e71;
`

const BottomImageWrapper = styled.div`
  position: relative;
  background-color: white;
`

const BottomCheckImage = styled.img`
  position: absolute;
  top: -10.55vw;
  right: 5vw;
  width: 22.20vw;
  z-index: 1;
`

const BottomBackImage = styled.img`
  position: relative;
  z-index: 0;
  
  height: 42.45vw;
`