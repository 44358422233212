import React from "react";
import styled from "styled-components";
import number02Image from "../../../../../media/image/ContractInfo/Contract/02.webp";
import main02Image from "../../../../../media/mobileImage/ContractInfo/Contract/shutterstock-1972788395.jpg";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export const ContractMobile = () => {
  return(
    <AnimationOnScroll animateIn="animate__fadeIn" animatePreScroll={false} animateOnce={true}>
    <ContractContainer>
      <HeaderWrapper>
        <NumberImage src={number02Image} alt={"number-02-image"} loading="lazy"/>
        <Title>계약체결</Title>
      </HeaderWrapper>

        <MainImage src={main02Image} loading="lazy"/>

      <SubTitle>{`➊ 상담 신청 후 안내 자료 및 계약서 발송   
➋ 자료 및 계약서 검토 후 계약 체결   
➌ 이용자 등록 및 사전 설정`}</SubTitle>
    </ContractContainer>
    </AnimationOnScroll>
  )
}

const ContractContainer = styled.div`
 margin-top: 12.67vw;
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3vw;
`

const NumberImage = styled.img`
  width: 15.82vw;
  margin-right: 5.65vw;
`

const Title = styled.div`
  font-size: 4.64vw;
  letter-spacing: -1.43px;
  font-weight: bold;
  font-family: NotoSansCJKkr_Bold, serif;
  color: #414042;
`

const MainImage = styled.img`
  width: 97.74vw;
  margin-bottom: 4vw;
`

const SubTitle = styled.div`
  white-space: pre-wrap;
  font-size:  3.95vw;
  font-family: NotoSansCJKkr_Regular,serif;
  letter-spacing: -1.23px;
  color: #414042;
`