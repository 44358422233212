import React from "react";
import {useMediaQuery} from "react-responsive";

// For Responsive Table and Mobile
export const useIsMobile = () => {
  const [isMobileOrTablet, setIsMobileOrTablet] = React.useState(false);
  const isBool = useMediaQuery({query: '(max-width: 724px)'});

  React.useEffect(() => {
    setIsMobileOrTablet(isBool);
  }, [isBool])

  return isMobileOrTablet;
}
