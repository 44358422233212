import React from "react";
import styled from "styled-components";
import number03Image from "../../../../../media/image/ContractInfo/UsageService/03.webp";
import mainImage from "../../../../../media/image/ContractInfo/UsageService/shutterstock-2089227466.webp";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export const UsageServiceMobile = () => {
  return(
    <AnimationOnScroll animateIn="animate__fadeIn" animatePreScroll={false} animateOnce={true}>
    <UsageServiceContainer>
      <TextWrapper>
        <HeaderWrapper>
          <NumberImage src={number03Image} alt={"number-03-image"} loading="lazy"/>
          <Title>서비스 이용</Title>
        </HeaderWrapper>

        <MainImage src={mainImage} alt={"main-image-03"} loading="lazy"/>

        <TextBodyWrapper>
          <TextBody>➊ 국가대표 <span style={{fontWeight:'bold'}}>3000-3000</span> 또는 고객용 어플리케이션 콜 접수</TextBody>
          <TextBody>{`➋ 상담사의 출발지, 경유지, 도착지 등 확인,
      요금안내 후 접수 완료`}</TextBody>
          <TextBody>{`➌ 국가대표 상황실에서 기사관리 시스템으로 
      자사기사 배차(지정, 다중 배차)`}</TextBody>
          <TextBody>➍ 국가대표 자사기사 확인 후 운행 시작(정장, 넥타이)</TextBody>
          <TextBody>➎ 목적지까지 안전 운행, 완벽 주차 마무리 후 전자서명</TextBody>
        </TextBodyWrapper>
      </TextWrapper>
    </UsageServiceContainer>
    </AnimationOnScroll>
  )
}

const UsageServiceContainer = styled.div`
  margin-top: 30vw;
  background-color: #E6E7E8;
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-right:  1.94vw;
`

const MainImage = styled.img`
  width: 86.04vw;
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.95vw;
  padding-top: 12px;
`

const NumberImage = styled.img`
  width: 15.82vw;
  margin-right: 5.65vw;
`

const Title = styled.div`
  font-size: 4.64vw;
  letter-spacing: -1.43px;
  font-weight: bold;
  font-family: NotoSansCJKkr_Bold, serif;
  color: #414042;
`

const TextWrapper = styled.div`
  margin-top: 0.56vw;
`

const TextBodyWrapper = styled.div`
  margin-top: 4.64vw;
`

const TextBody = styled.div`
  letter-spacing: -1.23px;
  font-size: 3.95vw;
  font-family: NotoSansCJKkr_Regular,serif;
  color: #414042;
  white-space: pre-wrap;
`
