import React from "react";
import styled from "styled-components";
import number01Image from '../../../../media/image/ContractInfo/PhoneService/01.webp'
import main01Image from '../../../../media/image/ContractInfo/PhoneService/shutterstock-1913637745.webp'
import {useIsDesktop} from "../../../../hooks/useIsDesktop";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export const PhoneService = () => {
  const isDesktop = useIsDesktop();

  return(
    <AnimationOnScroll animateIn="animate__fadeIn" animatePreScroll={false} delay={1000} animateOnce={true}>
    <PhoneServiceContainer isDesktop={isDesktop} >
      <HeaderWrapper isDesktop={isDesktop}>
        <NumberImage src={number01Image} alt={"number-01-image"} isDesktop={isDesktop} loading="lazy"/>
        <Title isDesktop={isDesktop}>전화상담</Title>
      </HeaderWrapper>

      <MainImage src={main01Image} isDesktop={isDesktop} loading="lazy"/>

      <SubTitle isDesktop={isDesktop}>서비스 및 법인 계약 문의 : <TextSpan style={{}}>080-132-3000</TextSpan></SubTitle>

      <OnlineService isDesktop={isDesktop}>온라인 상담 신청</OnlineService>

    </PhoneServiceContainer>
    </AnimationOnScroll>
  )
}

const PhoneServiceContainer = styled.div`
  position: absolute;
  left: ${props => props.isDesktop ? '6.98vw' : '125px'};
  top:  ${props => props.isDesktop ? '15.68vw' : '281px'};
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.isDesktop ? '0.95vw' : '17px'};
`

const NumberImage = styled.img`
  width: ${props => props.isDesktop ? '5.47vw' : '98px'};
  margin-right: ${props => props.isDesktop ? '2.68vw' : '48px'};
`

const Title = styled.div`
  font-size: ${props => props.isDesktop ? '1.59vw' : '28px'};
  letter-spacing: -1.43px;
  font-weight: bold;
  font-family: NotoSansCJKkr_Bold, serif;
  color: #414042;
`

const MainImage = styled.img`
  width: ${props => props.isDesktop ? '21.2vw' : '379px'};
`

const SubTitle = styled.div`
  font-size:  ${props => props.isDesktop ? '1.48vw' : '26px'};
  font-family: NotoSansCJKkr_Regular,serif;
  letter-spacing: -1.33px;
  color: #414042;
  margin-bottom: ${props => props.isDesktop ? '2.07vw' : '37px'};
`

const OnlineService = styled.div`
  text-align: center;
  background-color: #1b75bc;
  height: ${props => props.isDesktop ? '2.18vw' : '39px'};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: ${props => props.isDesktop ? '1.37vw' : '25px'};
  letter-spacing: -1.23px;
  font-family: NotoSansCJKkr_Bold,serif;
  
`

const TextSpan = styled.span`
  color: #195b90;
  font-family: NotoSansCJKkr_Bold,serif;
  letter-spacing: -1.32px;
  font-weight: bold;
`