import React from "react";
import styled from "styled-components";
import OneSectionImage from '../../../../../media/mobileImage/IntroJob/sub-01.webp'
import icon1 from '../../../../../media/mobileImage/IntroJob/OneSectionMobile/icon-01.webp'
import icon2 from '../../../../../media/mobileImage/IntroJob/OneSectionMobile/icon-02.webp'
import icon3 from '../../../../../media/mobileImage/IntroJob/OneSectionMobile/icon-03.webp'
import icon4 from '../../../../../media/mobileImage/IntroJob/OneSectionMobile/icon-04.webp'
import icon5 from '../../../../../media/mobileImage/IntroJob/OneSectionMobile/icon-05.webp'
import { AnimationOnScroll } from 'react-animation-on-scroll';

export const OneSectionMobile = () => {

  return(
    <>
      <OneSectionContainer>
        <OneHeader>
          <OneTitleSection>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <SectionTitleBar color={'#478cc3'} />
              <ShapIcon>{`  # `}</ShapIcon>
              <OneTitle>법인대리운전</OneTitle>
            </div>
            <OneSubTitle>법인사업자 임원분들을 위한 프리미엄 서비스</OneSubTitle>
          </OneTitleSection>
        </OneHeader>

        <div style={{display:"flex", justifyContent:'center', padding: '7.75vw 0'}}>
          <OneTitleImage src={OneSectionImage} alt={'one-title-mobile-image'} loading="lazy"/>
        </div>
      </OneSectionContainer>

      <AnimationOnScroll animateIn="animate__fadeIn" animatePreScroll={false} animateOnce={true}>
        <IconItemWrapaper>

          <ItemLine />

            <ItemWrapper>
              <IconImage src={icon1} loading="lazy"/>
              <ItemText>
                {`국내 최초, 최대 
법인전문 운전대행
서비스의 노하우`}
              </ItemText>
            </ItemWrapper>

            <ItemWrapper>
              <IconImage src={icon2} loading="lazy"/>
              <ItemText>
                {`월 단위의 편리한
후불 결제 및 세금계산서 발행
비용처리`}
              </ItemText>
            </ItemWrapper>

            <ItemWrapper>
              <IconImage src={icon3} loading="lazy"/>
              <ItemText>
                {`Prime-Time때
자사 베테랑 기사를 최우선 배정`}
              </ItemText>
            </ItemWrapper>

            <ItemWrapper>
              <IconImage src={icon4} loading="lazy"/>
              <ItemText>
                {`중요 바이어 대상
철도역, 공항 마중 전용기사 및 통역`}
              </ItemText>
            </ItemWrapper>

            <ItemWrapper>
              <IconImage src={icon5} loading="lazy"/>
              <ItemText>
                {`골프장 운행, 지방 출장 및
VIP 의전`}
              </ItemText>
            </ItemWrapper>


        </IconItemWrapaper>
        </AnimationOnScroll>

    </>
  )
}

const OneSectionContainer = styled.div`
  width: 100%;
  background-color: #e6e7e8;
`

const SectionTitleBar = styled.div`
  background-color: ${props => props.color};
  width: 6.38vw;
  height: 1.37vw;
`

const OneHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const OneTitleSection = styled.div`
  margin-top: 38px;
`

const ShapIcon = styled.div`
  font-size: 5.93vw;
  font-weight: 300;
  letter-spacing: -2.05px;
  white-space: pre-wrap;
  color: #3987c5;
  font-family: NotoSansCJKkr_Light, serif;
`

const OneTitle = styled.div`
  font-size: 5.93vw;
  font-weight: 900;
  letter-spacing: -2.05px;
  color: #3987c5;
  font-family: NotoSansCJKkr_Black, serif;
`

const OneSubTitle = styled.div`
  font-size: 4.96vw;
  font-weight: 500;
  letter-spacing: -1.43px;
  color: #414042;
  margin-left: 11.62vw;
`

const OneTitleImage = styled.img`
  width: 78.3vw;
`

const IconItemWrapaper = styled.div`
  position: relative;
  margin-top: 5vw;
  margin-bottom: 8vw;
  padding-left: 9.85vw;
`

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 2;
  margin-bottom: -7vw;
`

const IconImage = styled.img`
  width: 32.04vw;
  z-index: 1;
`

const ItemText = styled.div`
  white-space: pre-wrap;
  text-align: left;
  margin-top: -11vw;
  font-size: 3.95vw;
  letter-spacing: -1.23px;
  font-family: NotoSansCJKkr_Regular, serif;
  color: #414042;;
`

const ItemLine = styled.div`
  width: 2px;
  height: 134.30vw;
  background-color: #3987c5;
  position: absolute;
  top: 10vw;
  left: 23vw;
  z-index: 0;
`