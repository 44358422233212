import React from "react";
import {useMediaQuery} from "react-responsive";

// For Responsive Desktop
export const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = React.useState(false);
  const isBool = useMediaQuery({query: '(max-width: 1700px)'});

  React.useEffect(() => {
    setIsDesktop(isBool);
  }, [isBool])

  return isDesktop;
}
