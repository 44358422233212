import React from "react";
import styled from "styled-components";
import TwoSectionImage from '../../../../media/image/IntroJob/TwoSection/sub-02@3x.webp'
import icon1 from "../../../../media/image/IntroJob/TwoSection/icon-01.webp";
import icon2 from "../../../../media/image/IntroJob/TwoSection/icon-02.webp";
import icon3 from "../../../../media/image/IntroJob/TwoSection/icon-03.webp";
import icon4 from "../../../../media/image/IntroJob/TwoSection/icon-04.webp";
import {useIsDesktop} from "../../../../hooks/useIsDesktop";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export const TwoSection = () => {
  const isDesktop = useIsDesktop();

  return(
    <AnimationOnScroll animateIn="animate__fadeInLeftBig" animatePreScroll={false} animateOnce={true}>
    <TwoSectionContainer isDesktop={isDesktop}  >

      <AnimationOnScroll animateIn="animate__fadeIn" animatePreScroll={false} delay={1000} animateOnce={true}>
      <TwoHeader isDesktop={isDesktop}  >
        <TwoTitleSection isDesktop={isDesktop}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <SectionTitleBar color={'#093b64'} isDesktop={isDesktop} />
            <ShapIcon isDesktop={isDesktop}>{`  # `}</ShapIcon>
            <TwoTitle isDesktop={isDesktop}>3000-3000 콜서비스</TwoTitle>
          </div>
          <TwoSubTitle isDesktop={isDesktop}>합리적인 서비스 경험, 국가대표가 빠르고 안전하게 모십니다</TwoSubTitle>
        </TwoTitleSection>

        <TwoTitleImage src={TwoSectionImage} alt={'two-title-image'} isDesktop={isDesktop} loading="lazy"/>
      </TwoHeader>

      <IconItemWrapaper isDesktop={isDesktop}  >

        <ItemLine isDesktop={isDesktop}/>

        <div style={{display:"flex", justifyContent:"space-between", width: '80%'}}>

          <ItemWrapper isDesktop={isDesktop}>
            <IconImage src={icon1} isDesktop={isDesktop} loading="lazy"/>
            <ItemText isDesktop={isDesktop}>
              {`직원 분들을 위하여 비용의 10~30%까지 가격을 낮춘 혁신적이고 합리적인 서비스\n`}
              <ItemSpan isDesktop={isDesktop}>
                {'서울/경기/수도권 15,000부터 '}
              </ItemSpan>
            </ItemText>
          </ItemWrapper>

          <ItemWrapper isDesktop={isDesktop}>
            <IconImage src={icon2} isDesktop={isDesktop} loading="lazy"/>
            <ItemText isDesktop={isDesktop}>
              운행은 먼저, 결제는 후불로 편하게
이용 가능하며 자사 기사 우선 배치 
지연 시 연합기사 후속 배치
            </ItemText>
          </ItemWrapper>

          <ItemWrapper isDesktop={isDesktop}>
            <IconImage src={icon3} isDesktop={isDesktop} loading="lazy"/>
            <ItemText isDesktop={isDesktop}>
              현 3만여개 추산 대리운전 업체 중
국가대표는 20년 전통의 법인전문 운전대행
서비스 회사로 특별한 시스템과 노하우로
특화된 서비스 제공
            </ItemText>
          </ItemWrapper>

          <ItemWrapper isDesktop={isDesktop}>
            <IconImage src={icon4} isDesktop={isDesktop} loading="lazy"/>
            <ItemText isDesktop={isDesktop}>
              법인 고객 중 직원명단 등록 후 이용시
기본 서비스 외 입원비 보상, 렌터카,
법규위반 보상등 다양한 혜택 제공
            </ItemText>
          </ItemWrapper>

        </div>
      </IconItemWrapaper>
        </AnimationOnScroll>
    </TwoSectionContainer>
    </AnimationOnScroll>
  )
}



const TwoSectionContainer = styled.div`
  width: 100%;
  background-color: #b4bac1;
  padding: ${props => props.isDesktop ? '1.96vw 0' : '35px 0' };
`

const TwoHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const SectionTitleBar = styled.div`
  background-color: ${props => props.color};
  width: ${props => props.isDesktop ? '5.25vw' : '94px' };
  height: ${props => props.isDesktop ? '0.61vw' : '11px' };
`

const TwoTitleSection = styled.div`
  margin-top: ${props => props.isDesktop ? '2.12vw' : '38px' };
`

const ShapIcon = styled.div`
  font-size: ${props => props.isDesktop ? '2.29vw' : '41px' };
  font-weight: 300;
  letter-spacing: -2.05px;
  white-space: pre-wrap;
  color: #3987c5;
  font-family: NotoSansCJKkr_Light, serif;
`

const TwoTitle = styled.div`
  font-size: ${props => props.isDesktop ? '2.29vw' : '41px' };
  font-weight: 900;
  letter-spacing: -2.05px;
  color: #3987c5;
  font-family: NotoSansCJKkr_Black, serif;
`

const TwoSubTitle = styled.div`
  font-size: ${props => props.isDesktop ? '1.59vw' : '28.5px' };
  font-weight: 500;
  letter-spacing: -1.43px;
  color: #414042;
  margin-left: ${props => props.isDesktop ? '7.65vw' : '137px' };
`

const TwoTitleImage = styled.img`
  width: ${props => props.isDesktop ? '23.58vw' : '422px' };
  margin-right: ${props => props.isDesktop ? '4.13vw' : '74px' };
`

const IconItemWrapaper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: ${props => props.isDesktop ? '2.12vw' : '38px' };
`

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  width: 23.5%;
`

const IconImage = styled.img`
  margin-left: ${props => props.isDesktop ? '1.68vw' : '30px' };
  width: ${props => props.isDesktop ? '8.55vw' : '153px' };
  
`

const ItemText = styled.div`
  white-space: pre-wrap;
  text-align: center;
  margin-top: ${props => props.isDesktop ? '-1.40vw' : '-25px' };
  font-size: ${props => props.isDesktop ? '1.28vw' : '23px' };
  letter-spacing: -1.23px;
  font-family: NotoSansCJKkr_Regular, serif;
  color: #414042;;
`

const ItemLine = styled.div`
  width: 60%;
  height: ${props => props.isDesktop ? '0.11vw' : '2px' };
  background-color: #ffffff;
  position: absolute;
  top: ${props => props.isDesktop ? '3.63vw' : '65px' };
`

const ItemSpan = styled.span`
  font-size: ${props => props.isDesktop ? '0.92vw' : '16.5px' };
  letter-spacing: -0.82px;
  font-family: NotoSansCJKkr_Bold, serif;
  color: #414042;
`