import React from "react";
import styled from "styled-components";
import number04Image from "../../../../media/image/ContractInfo/PublishTex/04.webp";
import main04Image from "../../../../media/image/ContractInfo/PublishTex/shutterstock-770002474.webp";
import {useIsDesktop} from "../../../../hooks/useIsDesktop";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export const PublishTex = () => {
  const isDesktop = useIsDesktop();

  return(
    <AnimationOnScroll animateIn="animate__fadeIn" animatePreScroll={false} delay={2500} animateOnce={true} >
    <PublishTexContainer isDesktop={isDesktop}  >
      <HeaderWrapper isDesktop={isDesktop}>
        <NumberImage src={number04Image} alt={"number-01-image"} isDesktop={isDesktop} loading="lazy"/>
        <Title isDesktop={isDesktop}>세금계산서 발행</Title>
      </HeaderWrapper>

      <MainImage src={main04Image} isDesktop={isDesktop} loading="lazy"/>

      <SubTitleWrapper isDesktop={isDesktop}>
        <SubTitle isDesktop={isDesktop}>➊ 월단위 이용내역서 제공</SubTitle>
        <SubTitle isDesktop={isDesktop}>➋ 내역 검토 후 전자세금계산서 발행</SubTitle>
      </SubTitleWrapper>

    </PublishTexContainer>
    </AnimationOnScroll>
  )
}

const PublishTexContainer = styled.div`
  position: absolute;
  left: ${props => props.isDesktop ? '6.98vw' : '125px'};
  bottom: ${props => props.isDesktop ? '1.68vw' : '30px'};
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.isDesktop ? '0.95vw' : '17px'};
`

const NumberImage = styled.img`
  width: ${props => props.isDesktop ? '5.47vw' : '98px'};
  margin-right: ${props => props.isDesktop ? '2.68vw' : '48px'};
`

const Title = styled.div`
  font-size: ${props => props.isDesktop ? '1.59vw' : '28px'};
  letter-spacing: -1.43px;
  font-weight: bold;
  font-family: NotoSansCJKkr_Bold, serif;
  color: #414042;
`

const MainImage = styled.img`
  width: ${props => props.isDesktop ? '29.61vw' : '530px'};
`

const SubTitleWrapper = styled.div`
  margin-top: ${props => props.isDesktop ? '1.01vw' : '18px'};
`

const SubTitle = styled.div`
  font-size:  ${props => props.isDesktop ? '1.37vw' : '25px'};
  font-family: NotoSansCJKkr_Regular,serif;
  letter-spacing: -1.23px;
  color: #414042;
`