import React from "react";
import styled from "styled-components";
import number05Image from "../../../../media/image/ContractInfo/Payment/05.webp";
import main05Image from "../../../../media/image/ContractInfo/Payment/shutterstock-635097344.webp";
import {useIsDesktop} from "../../../../hooks/useIsDesktop";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export const Payment = () => {
  const isDesktop = useIsDesktop();

  return(
    <AnimationOnScroll animateIn="animate__fadeIn" animatePreScroll={false} delay={3000} animateOnce={true}>
    <PaymentContainer isDesktop={isDesktop} >
      <HeaderWrapper isDesktop={isDesktop}>
        <NumberImage src={number05Image} alt={"number-01-image"} isDesktop={isDesktop} loading="lazy"/>
        <Title isDesktop={isDesktop}>결제</Title>
      </HeaderWrapper>

      <ImageWrapper isDesktop={isDesktop}>
        <RowImageWrapper isDesktop={isDesktop}>
          <MainImage src={main05Image} isDesktop={isDesktop} loading="lazy"/>

          <BankAccountWrapper isDesktop={isDesktop}>
            <BankAccount isDesktop={isDesktop}>신용협동조합 : 131-021-834882</BankAccount>
            {/*<BankAccount isDesktop={isDesktop}>기업은행 : 220-031146-04-010</BankAccount>*/}
            {/*<BankAccount isDesktop={isDesktop}>우리은행 : 418-068635-13-101</BankAccount>*/}
            {/*<BankAccount isDesktop={isDesktop}>신한은행 : 100-028-138243</BankAccount>*/}
            {/*<BankAccount isDesktop={isDesktop}>하나은행 : 176-910006-86504</BankAccount>*/}
          </BankAccountWrapper>
        </RowImageWrapper>
        <ImageText isDesktop={isDesktop}>➊ 신용협동조합 : 131-021-834882</ImageText>
        <ImageText isDesktop={isDesktop}>➋ 계산서 수령 후 입금</ImageText>
      </ImageWrapper>
    </PaymentContainer>
    </AnimationOnScroll>
  )
}

const PaymentContainer = styled.div`
  position: absolute;
  bottom: ${props => props.isDesktop ? '1.68vw' : '30px'};
  right: ${props => props.isDesktop ? '5.59vw' : '100px'};
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.isDesktop ? '0.95vw' : '17px'};
`

const NumberImage = styled.img`
  width: ${props => props.isDesktop ? '5.47vw' : '98px'};
  margin-right: ${props => props.isDesktop ? '2.68vw' : '48px'};
`

const Title = styled.div`
  font-size: ${props => props.isDesktop ? '1.59vw' : '28px'};
  letter-spacing: -1.43px;
  font-weight: bold;
  font-family: NotoSansCJKkr_Bold, serif;
  color: #414042;
`

const ImageWrapper = styled.div`

`

const MainImage = styled.img`
  width:  ${props => props.isDesktop ? '29.61vw' : '530px'};
  margin-bottom: ${props => props.isDesktop ? '0.84vw' : '15px'};
`

const ImageText = styled.div`
  font-size: ${props => props.isDesktop ? '1.37vw' : '25px'};
  letter-spacing: -1.23px;
  color: #414042;
  font-family: NotoSansCJKkr_Regular,serif;
`

const RowImageWrapper = styled.div`
  display: flex;
`
const BankAccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-bottom: ${props => props.isDesktop ? '0.56vw' : '10px'};
  margin-left: ${props => props.isDesktop ? '1.34vw' : '24px'};
`

const BankAccount = styled.div`
  font-size:  ${props => props.isDesktop ? '1.37vw' : '25px'};
  letter-spacing: ${props => props.isDesktop ? '-0.07vw' : '-1.23px'};
  color: #414042;
  font-family: NotoSansCJKkr_Regular,serif;
  opacity: 0;
`
