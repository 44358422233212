import React from "react";
import styled from "styled-components";
import youtubeIcon from '../../media/image/ShareFooter/youtube.webp'
import facebookIcon from '../../media/image/ShareFooter/fb.webp'
import instaIcon from '../../media/image/ShareFooter/insgta.webp'
import {useIsDesktop} from "../../hooks/useIsDesktop";

export const ShareFooter = () => {
  const isDesktop = useIsDesktop();

  return(
    <ShareFooterContainer isDesktop={isDesktop}>
      <LeftTextWrapper isDesktop={isDesktop}>
        <CompanyName isDesktop={isDesktop}>㈜국가대표</CompanyName>

        <BodyText isDesktop={isDesktop}>
          {`서울틀별시 노원구 동일로207길 23, 4층 414호
대표 : 김성환  사업자번호 : 599-86-02759
국가대표 : 3000-3000
고객문의 : 080-132-3000`}
        </BodyText>

        <CopyRightsText isDesktop={isDesktop}>
          copyrights © 2022 국가대표. all rights reserved.
        </CopyRightsText>
      </LeftTextWrapper>

      <SocialLogoWrapper isDesktop={isDesktop}>
        <SocailLogoImage src={youtubeIcon} isDesktop={isDesktop} loading="lazy"/>
        <SocailLogoImage src={facebookIcon} isDesktop={isDesktop} loading="lazy"/>
        <SocailLogoImage src={instaIcon} isDesktop={isDesktop} loading="lazy"/>
      </SocialLogoWrapper>
    </ShareFooterContainer>
  )
}

const ShareFooterContainer = styled.div`
  background-color: #1e3241;
  padding: ${props => props.isDesktop ? '1.40vw 3.52vw' : '25px 63px'};
  display: flex;
  justify-content: space-between;
`

const LeftTextWrapper = styled.div`

`

const CompanyName = styled.div`
  color: #d1d3d4;
  font-size: ${props => props.isDesktop ? '1.15vw' : '20.5px'};
  font-family: NotoSansCJKkr_Bold,serif;
  font-weight: bold;
  margin-bottom: ${props => props.isDesktop ? '1.62vw' : '29px'};
`

const BodyText = styled.div`
  color: #d1d3d4;
  white-space: pre-wrap;
  font-size: ${props => props.isDesktop ? '0.89vw' : '16px'};
  font-family: NotoSansCJKkr_DemiLight,serif;
  font-weight: 300;
`

const CopyRightsText = styled.div`
  color: #d1d3d4;
  font-size: ${props => props.isDesktop ? '0.89vw' : '16px'};
  font-family: NotoSansCJKkr_DemiLight,serif;
  font-weight: 300;
  margin-top: ${props => props.isDesktop ? '3.24vw' : '58px'};
`

const SocialLogoWrapper = styled.div`
  
`

const SocailLogoImage = styled.img`
  width: ${props => props.isDesktop ? '2.23vw' : '40px'};
  margin-left: ${props => props.isDesktop ? '1.90vw' : '34px'};
  cursor: pointer;
`