import React from "react";
import styled from "styled-components";
import image2 from "../../media/image/TopMain/mainTop1.jpg"
import image3 from "../../media/image/TopMain/mainTop2.jpg"
import Scroll from 'react-scroll';
import { Carousel } from 'react-responsive-carousel';
import {useIsDesktop} from "../../hooks/useIsDesktop";

export const TopMain = () => {
  let Element = Scroll.Element;
  const isDesktop = useIsDesktop();

  return (
      <a href={'https://apps.apple.com/kr/app/3000-3000-%EB%8C%80%EB%A6%AC%EC%9A%B4%EC%A0%84/id1662041820'}>
        <Element name={'TopMain-Scroll'}>
          <TopMainContainer>
            <AddressText isDesktop={isDesktop}>30003000.kr</AddressText>
            <Carousel autoPlay={true} infiniteLoop={true} interval={5000} showArrows={false} showStatus={false} >
              <div>
                <ImageWrapper  alt={'top-main-img1'} src={image2} loading="lazy"/>
              </div>
              <div>
                <ImageWrapper  alt={'top-main-img1'} src={image3} loading="lazy"/>
              </div>
            </Carousel>
          </TopMainContainer>
        </Element>
      </a>
  )
}


const TopMainContainer = styled.div`
  margin-bottom: -54px;
  position: relative;
`

const ImageWrapper = styled.img`
  width: 100%;
`

const AddressText = styled.div`
  position: absolute;
  z-index: 1;
  color: #ffffff;
  font-size: ${props => props.isDesktop ? '1vw' : '18px'};
  font-family: NotoSansCJKkr_Bold,serif;
  margin: ${props => props.isDesktop ? '1.90vw 2.01vw' : '34px 36px'};
`